import React from "react";
import tw from "twin.macro";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import { SectionHeading,Subheading } from "components/misc/Headings.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center  max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-900 xl:text-6xl`;
const Heading2=tw(Subheading)`text-left text-primary-900 text-xl`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;

export default ({
  heading = "ONE STOP SOLUTION PROVIDER FOR FOLLOWING",
  heading2 ="STRIVE FOR EXCELLENCE",
  buttonRounded = true,
  features = ["Education Consultation", "Service Matters", "Career Counselling","Investment/Stock Market Trading", "Manpower Service Provider"],
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#eeplRecord">Our Record</NavLink>
      <NavLink href="#eeplServices">Services</NavLink>
      <NavLink href="#eeplAbout">About</NavLink>
      <NavLink href="#eeplTeam">Our Team</NavLink>
      <NavLink href="#eeplFaq">FAQ</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink css={buttonRoundedCss} href="#contactUsEpl">
        Contact Us
      </PrimaryLink>
    </NavLinks>
  ];
  const defaultSteps = [
    {
      heading: "Register",
      description: "Strive to create value and make difference in enhancing the wealth generating potential, capabilities of a individuals."
    },
    {
      heading: "Download",
      description: "Strive to inspire the perspective students with optimistic approach for better future by expert guidance, counselling, teaching."
    },
    {
      heading: "Run",
      description: "Provide Dedicated, Sincere Service Manpower to be part of the team, they work to enhance effectiveness."
    }
  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <TextColumn>
            <Heading>MOTTO</Heading>
              <Heading2>{heading2}</Heading2>
            <Steps>
              {defaultSteps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
                  <StepText>
                    <FeatureText>{step.description}</FeatureText>
                  </StepText>
                </Step>
              ))}
            </Steps>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
