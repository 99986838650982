import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { SectionHeading } from "components/misc/Headings.js";
import defaultCardImage from "../../images/shield-icon.svg";
import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;


export default () => {

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Education Consultation",
      description: "We have one of most experience team to give guidance, counselling prepares prospective students for the higher education and competitive exams."
    },
    { imageSrc: SupportIconImage, title: "Service Matters",
    description: "We have dedicated team to provide consultancy in Service Matters."
  },
    { imageSrc: CustomizeIconImage, title: "Career Counselling" ,
    description: "The Career Counselling is a essential for perspective students not only to seeking admission but also to choose best career as per the individual aptitude."
  },
    { imageSrc: ReliableIconImage, title: "Investment/Stock Market Trading",
    description: "We are in association with Motilal Oswal assure you for best guidance in the stock Market Trading."
   },
    { imageSrc: FastIconImage, title: "Manpower Service Provider",
    description: "Provide Dedicated, Sincere Service Manpower to be part of the team, they  work to enhance effectiveness."
   }
  ];

  return (
    <Container id="eeplServices">
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-primary-500" >Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
